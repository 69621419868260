<template>
  <div class="container">
    <div class="backPart" @click="goBack()">
      <img src="@/assets/back.png" width="80" height="160"/>
    </div>
    <div class="leftPart">
      <img src="@/assets/about/01.png" height="600"/>
    </div>
    <div class="rightPart">
      <h1>给植物打通电话吧</h1>
      <p>植物作为人类最常见的邻居之一，因其沉默内敛，也是我们最容易忽略的朋友。我们与其亲密共生，却往往很难注意到他们生命里的积极热忱。《给植物打通电话吧》通过艺术装置与交互系统的相互配合，放大了微弱却顽强的生命存在，是将植物生活拉进人类视野中的一次有趣尝试。</p>
      <p>通过解读植物在城市更新过程中，对周遭生活环境的感知及植物对近年人们在环保行动成果的积极反馈，形成具体的“通话”互动内容，给予人心灵上的疗愈，生活态度上的启迪，并试图再次将环境保护的话题带入城市更新的讨论中。</p>
      <p>《给植物打通电话吧》选取了南头古城小绿洲区域、南头古城及深圳市的部分代表植物作为通话的对象。希望借助本装置进行通话及交互，从一个特殊的视角重新认知城市空间中的共存关系，强调人与植物、人与城市、植物与城市相互观察、相互感知、平衡共生的空间张力;希望本装置成为不同城市、不同社区与相应在地植物的链接载体，构建植物、人、社区、城市之间更深的联系。</p>
    </div>
  </div>
</template>
<script>
export default {
  name: 'AboutView',
  methods: {
    goBack () {
      this.$router.push({ name: 'welcome' })
    }
  }
}
</script>
<style scoped>
.container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.rightPart, .backPart ,.leftPart{
  margin-top: auto;
  margin-bottom: auto;
}
.backPart {
  margin-left: 5vw;
}
.leftPart{
  overflow: hidden;
  border-top-left-radius: 50% 30%;
  border-top-right-radius: 50% 30%;
  width: 350px;
  min-width: 350px;
}
.rightPart{
  width: 600px;
  min-width: 600px;
  margin-right: 5vw;
  text-align: left;
  line-height: 1.5em;
}
.rightPart h1{
  color: #545536;
  margin-bottom: 2em;
}
</style>
