<template>
  <div class="container">
    <div class="backPart" @click="goBack()">
      <img src="@/assets/back.png" width="80" height="160"/>
    </div>
    <div class="leftPart">
      <el-carousel height="600px" indicator-position="outside">
        <el-carousel-item v-for="item in 3" :key="item">
          <img :src="`/images/${idStr}-${item}.png`" class="picItem"/>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="rightPart">
      <div class="name">
        {{ plant.name }}
      </div>
      <p class="infoItem">拉丁学名:{{ plant.latinName }}</p>
      <p class="infoItem">科:{{ plant.family }}</p>
      <p class="infoItem">属:{{ plant.genus }}</p>
      <p class="infoItem">关键词:{{ plant.keyword }}</p>
      <p class="infoItem">习性:{{ plant.habit }}</p>
      <p class="infoItem">出生地:{{ plant.birthPlace }}</p>
      <el-button round class="callButton" @click="handleCall"> 与我通话</el-button>
    </div>
  </div>
</template>

<script>
import { plantGet } from '@/api/api'

export default {
  name: 'PlantDetail',
  data () {
    return {
      id: 0,
      idStr: '',
      plant: {}
    }
  },
  mounted () {
    this.id = this.$route.params.id
    if (this.id < 10) {
      this.idStr = '0' + this.id
    } else {
      this.idStr = '' + this.id
    }
    this.getPlant()
  },
  methods: {
    goBack () {
      this.$router.push({ name: 'addressBook' })
    },
    getPlant () {
      plantGet(this.id).then(res => {
        if (res.code === 200) {
          this.plant = res.data.plant
        } else {
          this.$message.error({
            message: res.msg,
            duration: 10000
          })
          setTimeout(() => {
            this.$router.push({ name: 'addressBook' })
          }, 10000)
        }
      })
    },
    handleCall () {
      this.$router.push({
        name: 'call',
        params: { id: this.id }
      })
    }
  }
}
</script>

<style scoped>
.container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.rightPart, .backPart, .leftPart {
  margin-top: auto;
  margin-bottom: auto;
}

.backPart {
  margin-left: 5vw;
}
.leftPart {
  width: 400px;
  min-width: 400px;
}

.picItem {
  height: 95%;
  border-radius: 2em;
  overflow: hidden;
  box-shadow: #2f2f27 0 2px 5px;
  margin: 10px;
}

.rightPart {
  width: 40vw;
  height: 600px;
  min-width: 20em;
  margin-right: 5vw;
  text-align: left;
  line-height: 1.5em;
  color: #545536;
  font-weight: bold;
}

.rightPart .name {
  font-size: 80px;
  line-height: 1em;
  height: 2em;
}

.rightPart p {
  font-size: 26px;
}

.callButton {
  background: #545536 !important;
  border-color: #545536 !important;
  color: #fff !important;
  width: 10em;
  height: 2em;
  font-size: 24px;
  letter-spacing: 0.5em;
  margin-top: 30px;
}

.callButton:hover {
  background: #fff !important;
  border-color: #545536 !important;
  color: #545536 !important;
  opacity: 0.8;
}
</style>
