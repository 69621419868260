import request from '../utils/request'

export function plantGet (plantId) {
  return request({
    url: `/api/plant/${plantId}`,
    method: 'GET'
  })
}

export function questionsGet (plantId) {
  return request({
    url: `/api/question/${plantId}`,
    method: 'GET'
  })
}

export function answerGet (plantId, questionId) {
  return request({
    url: `/api/answer/${plantId}/${questionId}`,
    method: 'GET'
  })
}

export function audioGet (name) {
  return request({
    url: `/images/audio/${name}`,
    method: 'GET',
    responseType: 'blob'
  })
}
