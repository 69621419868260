<template>
  <div>
    <div class="navWrapper">
      <img class="backButton" src="@/assets/back02.png" height="40" @click="goBack"/>
      <div class="title">照片墙</div>
    </div>
    <div class="buttonWrapper">
      <el-button
        type="button"
        v-for="p in plantList"
        :key="p.id"
        round
        :class="['navButton', {'selected':p.id===dir}]"
        @click="handlePickPlant(p)"
      >
        {{ p.name }}
      </el-button>
    </div>
    <div class="stream">
      <div class="masonry">
        <div class="item" v-for="i in picNum" :key="dir+i">
          <el-image
            class="itemImg"
            :src="`/images/${dir}/${i}.jpg`"
            lazy @click.native="showPreview(`/images/${dir}/${i}.jpg`)"
          ></el-image>
        </div>
      </div>
    </div>
    <div>
      <el-dialog :visible.sync="previewVisible" :close-on-click-modal="true">
        <img :src="previewImage" alt="Preview Image" style="width: auto;max-height: 70vh;max-width: 100% "/>
      </el-dialog>
    </div>
  </div>
</template>

<script>
export default {
  name: 'pictureWall',
  data () {
    return {
      previewImage: '1',
      previewVisible: false,
      dir: '',
      picNum: 0,
      plantList: [
        {
          name: '射干',
          id: '01',
          num: 87
        },
        {
          name: '钝叶鸡蛋花',
          id: '02',
          num: 50
        },
        {
          name: '叶子花',
          id: '03',
          num: 49
        }, {
          name: '绒面肖竹芋',
          id: '04',
          num: 48
        },
        {
          name: '油棕',
          id: '05',
          num: 50
        },
        {
          name: '榕树',
          id: '06',
          num: 56
        }, {
          name: '琴叶榕',
          id: '07',
          num: 71
        },
        {
          name: '狐尾天门冬',
          id: '08',
          num: 42
        },
        {
          name: '蒲葵',
          id: '09',
          num: 54
        }, {
          name: '芭蕉',
          id: '10',
          num: 65
        },
        {
          name: '芒萁',
          id: '11',
          num: 47
        },
        {
          name: '鹅掌柴',
          id: '12',
          num: 49
        },
        {
          name: '美丽异木棉',
          id: '13',
          num: 50
        },
        {
          name: '落地杉',
          id: '14',
          num: 58
        }

      ]
    }
  },
  mounted () {
    this.dir = '01'
    this.picNum = 87
  },
  methods: {
    goBack () {
      this.$router.push({ name: 'welcome' })
    },
    showPreview (url) {
      this.previewVisible = true
      this.previewImage = url
    },
    handlePickPlant (p) {
      this.dir = p.id
      this.picNum = p.num
    }
  }
}
</script>

<style scoped>
.navWrapper {
  top: 0;
  display: flex;
  position: fixed;
  width: 100vw;
  z-index: 10;
  height: 8vh;
  align-items: center;
  line-height: 60px;
  background-color: rgb(247, 246, 237);
  box-shadow: #2f2f27 0 0 10px;
}

.backButton {
  position: absolute;
  /*top: 10px;*/
  left: 4vw;
  cursor: pointer;
}

.title {
  position: relative;
  margin: 0 auto;
  font-size: 26px;
  font-weight: bold;
  color: #545536;
}

.buttonWrapper {
  margin: 10vh auto 5vh auto;
  width: 90%;
  text-align: left;
}

.navButton {
  margin-top: 10px;
  color: #545536;
  background-color: white;
  border: solid #545536 1px;
  box-shadow: #b9b8b2 5px 5px 10px;
}

.navButton:hover {
  background-color: #545536;
  color: white;
  border: solid #545536 1px;
}
.selected, .navButton:focus{
  background-color: #545536;
  color: white;
  border: solid #545536 1px;
  box-shadow: #b9b8b2 5px 5px 10px;
}

.stream {
  margin: 5vh auto;
  width: 90%;
}

.masonry {
  column-gap: 0;
  column-count: 4;
}

.item {
  position: relative;
  margin: 10px;
  box-sizing: border-box;
  counter-increment: item-counter;
}

.itemImg {
  display: block;
  width: 100%;
  height: auto;
  cursor: zoom-in;
}

.item:nth-child(4n+1) {
  background-color: #cdd2fa;
}

.item:nth-child(4n+2) {
  background-color: #fcc9d4;
}

.item:nth-child(4n+3) {
  background-color: #def5e8;
}

.item:nth-child(4n+4) {
  background-color: #f1edc2;
}

</style>
