import Vue from 'vue'
import VueRouter from 'vue-router'

import WelcomeView from '@/views/WelcomeView'
import AboutView from '@/views/AboutView'
import AddressBook from '@/views/phone/AddressBook'
import PlantDetail from '@/views/phone/PlantDetail'
import CallView from '@/views/phone/CallView'
import DialogView from '@/views/phone/DialogView'
import PictureWall from '@/views/PictureWall'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/welcome'
  },
  {
    path: '/welcome',
    name: 'welcome',
    component: WelcomeView
  },
  {
    path: '/about',
    name: 'about',
    component: AboutView
  }, {
    path: '/picture',
    name: 'pictureWall',
    component: PictureWall
  },
  {
    path: '/address',
    name: 'addressBook',
    component: AddressBook
  },
  {
    path: '/plant/:id',
    name: 'plantDetail',
    component: PlantDetail
  }, {
    path: '/call/:id',
    name: 'call',
    component: CallView
  }, {
    path: '/dialog/:id',
    name: 'dialog',
    component: DialogView
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
