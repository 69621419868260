const idleMixin = {
  mounted () {
    if (this.$route.path !== '/welcome') {
      this.idleTimer = null
      console.log('reset')
      const resetIdleTimer = () => {
        clearTimeout(this.idleTimer)
        this.idleTimer = setTimeout(() => {
          this.goBackHome()
        }, 90000) // 设置无触控的超时时间，这里是20秒
      }

      this.handleUserActivity = () => {
        resetIdleTimer()
      }

      // 监听用户的鼠标移动、键盘输入、滚动等事件
      document.addEventListener('mousemove', this.handleUserActivity)
      document.addEventListener('keydown', this.handleUserActivity)
      document.addEventListener('scroll', this.handleUserActivity)

      // 初始化启动定时器
      resetIdleTimer()
    }
  },
  beforeDestroy () {
    // 在组件销毁之前移除事件监听和清除定时器
    document.removeEventListener('mousemove', this.handleUserActivity)
    document.removeEventListener('keydown', this.handleUserActivity)
    document.removeEventListener('scroll', this.handleUserActivity)
    clearTimeout(this.idleTimer)
  },
  methods: {
    goBackHome () {
      // 执行返回首页的操作
      // 例如使用路由进行页面跳转
      if (this.$route.path !== '/welcome') {
        this.$router.push('/welcome')
      }
    }
  }

}

export default idleMixin
