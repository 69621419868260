<template>
  <div>
    <div class="navWrapper">
      <img class="backButton" src="@/assets/back02.png" height="40" @click="goBack"/>
      <div class="title">通讯录</div>
    </div>
    <div class="container">
    <div class="picWrapper">
      <el-carousel
        :autoplay=false
        type="card"
        height="500px"
        arrow="never"
        style="overflow-x: hidden"
      >
        <my-carousel v-for="(item, i) in dataList" :key="i">
          <img class="imgItem" :src="require('@/assets/book/'+item)" alt="" @click="peekPlant(i+1)"/>
        </my-carousel>
      </el-carousel>
    </div></div>
  </div>
</template>

<script>
import MyCarousel from '@/components/MyCarousel'

export default {
  name: 'AddressBook',
  components: { MyCarousel },
  data () {
    return {
      dataList: ['01.png', '02.png', '03.png', '04.png', '05.png', '06.png', '07.png', '08.png', '09.png', '10.png',
        '11.png', '12.png', '13.png', '14.png']
    }
  },
  methods: {
    goBack () {
      this.$router.push({ name: 'welcome' })
    },
    peekPlant (_id) {
      this.$router.push({
        name: 'plantDetail',
        params: { id: _id }
      })
    }
  }
}
</script>

<style scoped>
.container {
  height: 92vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.navWrapper {
  display: flex;
  position: relative;
  height: 8vh;
  align-items: center;
  line-height: 60px;
  background-color: rgb(247, 246, 237);
  box-shadow: #2f2f27 0 0 10px;
}

.backButton {
  position: absolute;
  left: 4vw;
  cursor: pointer;
}

.title {
  position: relative;
  margin: 0 auto;
  font-size: 26px;
  font-weight: bold;
  color: #545536;
}

.picWrapper {
  width: 96vw;
}
.imgItem {
  width: 280px;
  height: 100%;
  border-radius: 4em;
}
</style>
