import axios from 'axios'
import { Message } from 'element-ui'

axios.defaults.withCredentials = true

// create an axios instance
const service = axios.create({
  // API
  withCredentials: true, // send cookies when cross-domain requests
  timeout: 100000 // request timeout
})

// response interceptor
service.interceptors.response.use(
  /** 1、头、request、返回值变化的策略、、todo
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * 可以通过自定义状态码进行全局的拦截控制,没有约定的话就不做处理
   */
  response => {
    const res = response.data
    if (res.code === 0) {
      Message({
        message: res.message || 'Error',
        type: 'error',
        duration: 3 * 1000
      })
      return
    }
    return res
  },
  error => {
    console.log('err request js err', error)
    if (error.response.data.length > 1000) {
      Message({
        message: '服务器出错',
        type: 'error',
        duration: 3 * 1000
      })
    } else {
      // Message({
      //   message: error.response.data.message,
      //   type: "error",
      //   duration: 1 * 1000
      // });
    }
    return Promise.reject(error)
  }
)
// request interceptor

// service.interceptors.request.use(function (config) {
//   const token = window.localStorage.getItem('accessToken')
//   if (token) {
//     config.headers.accessToken = token
//     return config
//   }
//   return config
// })

export default service
